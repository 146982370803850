<template>
    <b-row>
        <b-col>
            <zw-table ref="table"
                      tableName="debit_bank_transfers"
                      columnsPrefix="bank_transfers.column."
                      base-table="bank_transactions"
                      :items="myProvider"
                      :fields="fields"
                      :filter="filter"
                      :selected="selected"
                      title="common.menu.account-book.bank-transfers-debit"
                      :actions-list="getActions()"
                      :sticky="false"
                      sticky-header="100%"
            >
            </zw-table>
        </b-col>
    </b-row>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'
import moment from "moment";

export default {
    name: 'Debit',
    mixins: [commonTable],
    props: {
        'month': [String],
    },
    data() {
        return {
            tableOptions: {
                sortBy: 'date_time',
                sortDesc: true,
            },
            tableUniqKey: 'bank_debit',
            saveFilters: false,
        }
    },
    methods: {
        ...mapGetters('BankTransfers', ['getTransfersDebitTable', 'getTransfer']),
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'BankTransfers/fetchDebitTransfers', 'getTransfersDebitTable', {}, (tableData) => {
                if (tableData.params.filter.include_incoming_assigned) {
                    this.includeAssigned = tableData.params.filter.include_incoming_assigned
                }
            });
        },
        ctxAdditionalParams() {
            return {
                'include_incoming_assigned':  true,
            }
        },
        getActions() {
            return [
                {
                    title: this.$t('bank.button.sync_transactions'),
                    icon: 'sync',
                    click: () => {
                        this.$root.$children[0].openModal('bank-transfers', {}, null, {width: '800px'})
                    }
                },
                {
                    title: this.$t('bank.button.create_manual_transaction'),
                    icon: 'plus',
                    click: () => {
                        this.$root.$children[0].openModal('manual-bank-transfer-modal', {}, this.refreshTable, {width: '800px'})
                    }
                },
                {
                    title: this.$t('bank.button.transaction_from_incoming_invoice'),
                    icon: 'plus',
                    click: () => {
                        this.$root.$children[0].openModal('transaction-from-incoming-modal', {}, this.refreshTable)
                    }
                }
            ]
        },
    },
    mounted() {
        let monthStart = moment('01.' + this.month, 'DD.MM.YYYY').startOf('month').format('DD.MM.YYYY')
        let monthEnd = moment('01.' + this.month, 'DD.MM.YYYY').endOf('month').format('DD.MM.YYYY')

        this.$set(this.filter, 'date_time', monthStart + ';' + monthEnd)
    },
    watch: {
        month: function (val) {
            let monthStart = moment('01.' + this.month, 'DD.MM.YYYY').startOf('month').format('DD.MM.YYYY')
            let monthEnd = moment('01.' + this.month, 'DD.MM.YYYY').endOf('month').format('DD.MM.YYYY')

            this.$set(this.filter, 'date_time', monthStart + ';' + monthEnd)
        },
    }
}
</script>